import {
  backgroundImage,
  backgroundImageContainer,
  backgroundImageText,
  blackColor,
  cardTitle,
  container,
  hexToRgb,
  section,
  sectionDark,
  textCenter,
  title,
  whiteColor,
} from "assets/jss/material-kit-pro-react.jsx"
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.jsx"

const rounded = imagesStyles.imgRounded

const headlinerStyle = (theme) => ({
  backgroundImage: {
    ...backgroundImage,
    height: "1300px",
    [theme.breakpoints.down("md")]: {
      height: "1400px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "2600px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "2800px",
    },
  },
  backgroundImageText,
  cardDescription: {
    color: "#888",
    fontWeight: "400",
    fontSize: ".9rem !important",
    marginBottom: "1rem",
  },
  cardContent: {
    ...backgroundImageText,
  },
  cardBackgroundImage: {
    ...rounded,
    maxHeight: "300px",
    height: "300px",
  },
  cardTitle,
  container,
  eventLogo: {
    width: "225px",
    height: "150px",
    margin: "0 auto",
  },
  headerIconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1rem",
    fontWeight: "500",
    color: "#474a4d",
  },
  headlinerContainer: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  headlinerImage: {
    maxHeight: "250px",
    borderRadius: "6px",
    boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.5)",
  },
  headlinerItem: {
    "@media (max-width: 800px) and (orientation: landscape)": {
      flexGrow: "0",
      maxWidth: "83.333333%",
      flexBasis: "83.333333%",
    },
  },
  headlinerTitle: {
    ...title,
    ...textCenter,
    color: whiteColor,
    marginTop: "0",
  },
  icons: {
    width: "25px !important",
    height: "25px",
    color: "#3C4858",
    marginRight: "5px",
  },
  justifyContentCenter: {
    WebkitBoxPack: "center !important",
    MsFlexPack: "center !important",
    justifyContent: "center !important",
  },
  logoContainer: {
    width: "100%",
    height: "150px",
    display: "flex",
    alignItems: "center",
    marginBottom: "4rem",
  },
  marginTop60: {
    marginTop: "60px",
  },
  section: {
    ...section,
    ...sectionDark,
    ...backgroundImageContainer,
    position: "relative",
    "&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
      backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.7)",
    },
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "initial",
    },
  },
})

export default headlinerStyle
